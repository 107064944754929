import { useState } from "react";
import styled from "styled-components";
import { mobile, tablet } from "../responsive";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const MainContainer = styled.div`
    background: #1f2f4d;
    ${mobile({ padding: "0px" })}
    ${tablet({ padding: "0px" })}
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    flex-wrap: wrap;
    overflow: hidden;
    ${mobile({
        padding: "0px",
        display: "grid",
        gridTemplateColumns: "50% 50%",
    })}
    ${tablet({
        padding: "0px",
        display: "grid",
        gridTemplateColumns: "50% 50%",
    })}
`;

const MainCategoryContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    overflow: hidden;
    position: relative; /* Important for overlay positioning */
    cursor: pointer;
    ${mobile({ padding: "0px", height: "20vh" })}
    ${tablet({ padding: "0px", height: "40vh" })}
`;

const Overlay = styled.div`
    position: absolute; /* Overlay covers the entire container */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Black color with 50% opacity */
    z-index: 1; /* Ensures it is above the background image */
    transition: background 0.3s ease; /* Smooth transition for hover effect */
    
    &:hover {
        background: rgba(0, 0, 0, 0.9); /* Darker on hover */
    }
`;

const CategoryContainer = styled.div`
    z-index: 2; /* Content sits above the overlay */
    background-size: contain;
    background-position: center;
    &:hover {
        transform: scale(1.2);
        -webkit-transition: transform 0.4s ease-in-out;
    }
    ${mobile({ padding: "0px" })}
    ${tablet({ padding: "0px" })}
`;

const Series = styled.p`
    font-size: 2rem;
    text-transform: uppercase;
    color: white;
    text-align: center;
    max-width: 50vh;
    font-family: "Jost-Bold";
    z-index: 2; /* Ensure text is visible above the overlay */
    ${mobile({
        display: "block",
        textAlign: "center",
        fontSize: "1rem",
        padding: "0 1rem",
    })}
    ${tablet({
        display: "block",
        textAlign: "center",
        fontSize: "1.2rem",
        padding: "0 1rem",
    })}
`;


const Category = () => {
    // eslint-disable-next-line
    const [categories, setCategories] = useState([
        {
            url: "https://ik.imagekit.io/dnddecpho/airvitamin/service2_s2cxmV18E?updatedAt=1732806941089",
            series: <FormattedMessage id="category_1" defaultMessage="" />,
            cat: "G10-6M",
        },
        {
            url: "https://ik.imagekit.io/dnddecpho/airvitamin/service3_cdJui8gGZ?updatedAt=1732807361782",
            series: <FormattedMessage id="category_2" defaultMessage="" />,
            cat: "G10-1Y",
        },
       
    ]);

      // eslint-disable-next-line
    const [categories2, setCategories2] = useState([
        {
            url: "https://ik.imagekit.io/dnddecpho/airvitamin/service4_QnZ8lnPTI?updatedAt=1732807434644",
            series: <FormattedMessage id="category_3" defaultMessage="" />,
            cat: "G10-MAX",
        },
        {
            url: "https://ik.imagekit.io/dnddecpho/airvitamin/service5_tkUiNyvLA?updatedAt=1732807452730",
            series: <FormattedMessage id="category_4" defaultMessage="" />,
            cat: "G10-BROKEN",
        },
    ]);

    const history = useHistory();
    
    return (
<MainContainer>
    <Container>
        {categories.map((item) => (
            <MainCategoryContainer
                onClick={() => history.push(`product/${item.cat}`)}
                style={{
                    background: `url(${item.url})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                }}
                key={`${item.series}`}
            >
                <Overlay /> {/* Black overlay */}
                <CategoryContainer>
                    <Series>{item.series}</Series>
                </CategoryContainer>
            </MainCategoryContainer>
        ))}
    </Container>
    <Container>
        {categories2.map((item) => (
            <MainCategoryContainer
                onClick={() => history.push(`product/${item.cat}`)}
                style={{
                    background: `url(${item.url})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                }}
                key={`${item.series}`}
            >
                <Overlay /> {/* Black overlay */}
                <CategoryContainer>
                    <Series>{item.series}</Series>
                </CategoryContainer>
            </MainCategoryContainer>
        ))}
    </Container>
</MainContainer>

    );
};

export default Category;
