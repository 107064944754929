import { MailOutline, Phone, Room, BusinessCenter } from "@material-ui/icons";
import styled from "styled-components";
import { mobile, tablet } from "../responsive";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const Container = styled.div`
    padding: 20px 5rem;
    display: flex;
    ${mobile({ flexDirection: "column", padding: "0px" })}
    ${tablet({ flexDirection: "column", padding: "0px" })}
`;

const Left = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    ${mobile({
        padding: "2rem 3rem",
        justifyContent: "center",
        alignItems: "center",
    })}
    ${tablet({
        padding: "2rem 3rem",
        justifyContent: "center",
        alignItems: "center",
    })}
`;

const Logo = styled.img`
    max-height: 12vh;
    max-width: 18vh;
    ${mobile({ maxHeight: "15vh" })};
    ${tablet({ maxHeight: "15vh" })};
`;
const Desc = styled.p`
    margin: 20px 0px;
`;

const Center = styled.div`
    flex: 1;
    padding: 10px 20px;
    ${mobile({ display: "none" })}
    ${tablet({ display: "none" })}
`;

const Title = styled.h3`
    margin-bottom: 30px;
    ${mobile({ display: "none" })}
    ${tablet({ display: "none" })}
`;

const List = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    flex-wrap: wrap;
`;

const Right = styled.div`
    flex: 1;
    padding: 10px 20px;
    ${mobile({ backgroundColor: "#1f2f4d", color: "white", padding: "3rem" })}
    ${tablet({ backgroundColor: "#1f2f4d", color: "white", padding: "3rem" })}
`;

const ContactItem = styled.div`
    margin-bottom: 20px;
    display: flex;
    align-items: center;
`;

const Payment = styled.img`
    width: 50%;
`;

const CopyrightContainer = styled.div`
    background: #ac997b;
    color: white;
    padding: 20px 5rem;
    display: flex;
    justify-content: center;
    ${mobile({ flexDirection: "column", padding: "20px 3rem" })}
    ${tablet({ flexDirection: "column", padding: "20px 3rem" })}
`;

const Copyright = styled.div`
    text-align: center;
    font-size: 0.9rem;
    ${mobile({ fontSize: "0.8rem" })}
    ${tablet({ fontSize: "0.8rem" })}
`;

const Footer = () => {
    return (
        <>
            <Container>
                <Left>
                    <Logo src="https://ik.imagekit.io/dnddecpho/airvitamin/air-logo_xVbwmEdOS.jpg?updatedAt=1734429910967"></Logo>
                    <Desc>
                        <FormattedMessage id="footer_desc" defaultMessage="" />
                    </Desc>
                </Left>
                <Center>
                    <Title>
                        {" "}
                        <FormattedMessage
                            id="footer_title_1"
                            defaultMessage=""
                        />
                    </Title>
                    <List>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",

                                marginBottom: "10px",
                            }}
                            to="/"
                        >
                            <FormattedMessage
                                id="footer_subtitle_1"
                                defaultMessage=""
                            />
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",

                                marginBottom: "10px",
                            }}
                            to="/terms"
                        >
                            <FormattedMessage
                                id="footer_subtitle_2"
                                defaultMessage=""
                            />
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",

                                marginBottom: "10px",
                            }}
                            to="/privacy"
                        >
                            <FormattedMessage
                                id="footer_subtitle_3"
                                defaultMessage=""
                            />
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",

                                marginBottom: "10px",
                            }}
                            to="/delivery"
                        >
                            <FormattedMessage
                                id="footer_subtitle_4"
                                defaultMessage=""
                            />
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",

                                marginBottom: "10px",
                            }}
                            to="/refund"
                        >
                            <FormattedMessage
                                id="footer_subtitle_5"
                                defaultMessage=""
                            />
                        </Link>
                    </List>
                </Center>
                <Right>
                    <Title>
                        {" "}
                        <FormattedMessage
                            id="footer_title_2"
                            defaultMessage=""
                        />
                    </Title>
                    <ContactItem>
                        <BusinessCenter style={{ marginRight: "10px" }} /> Airsphere
                        Solution 202503051042 ( 003702515-W )
                    </ContactItem>
                    <ContactItem>
                        <Room style={{ marginRight: "10px" }} />
                        B33/5/31B, Persiaran Puteri Satu Taman Puteri, 43200
                        Cheras, Selangor
                    </ContactItem>
                    <ContactItem>
                        <Phone style={{ marginRight: "10px" }} /> +6011 6302
                        8626
                    </ContactItem>
                    <ContactItem>
                        <MailOutline style={{ marginRight: "10px" }} />{" "}
                        airspheresolution@gmail.com
                    </ContactItem>
                    <Payment src="https://i.ibb.co/Qfvn4z6/payment.png" />
                </Right>
            </Container>
            <CopyrightContainer>
                <Copyright>
                    © 2024 MGT VITAMIN RESEARCH AND DEVELOPMENT
                </Copyright>
            </CopyrightContainer>
        </>
    );
};

export default Footer;
