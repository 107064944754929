import styled from "styled-components";
import { mobile } from "../responsive";
import Footer from "../components/Footer";
import Newsletter from "../components/Newsletter";
import Announcement from "../components/Announcement";
import { useEffect } from "react";

const Container = styled.div`
    display: flex;
    margin-bottom: 3rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: black;
    ${mobile({ margin:"0" })}
`;
const Title = styled.h1`
    text-transform: uppercase;
    padding: 1rem;
    font-family: Jost-SemiBold;
    font-size: 40px;
    margin: 50px 0;
    background: black;
    color: white;
    ${mobile({ margin: "1rem", textAlign: "center", fontSize: "2rem" })}
`;

const AboutContainer = styled.div`
    padding: 3rem 5rem;
    display: grid;
    background: white;
    ${mobile({ padding: "1rem 3rem" })}
`;

const Heading = styled.h2`
    padding: 1.5rem 3rem;
    font-size: 22px;
    font-weight: 300;
    ${mobile({ textAlign: "left", padding: "0rem" })}
`;

const Desc = styled.div`
    padding: 0 3rem;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 2rem;
    line-height: 1.5;
    ${mobile({ textAlign: "left", margin: "0rem 0rem", padding: "1rem 0rem" })}
`;


const Refund = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Announcement />
            <Container>
                <Title>Refund Policy</Title>
                <AboutContainer>
                    <Heading>Refunds</Heading>
                    <Desc>
                        There are no refunds for Air Vitamin purchases.
                    </Desc>
                    <Heading>Eligibility for Refunds and Exchanges</Heading>
                    <Desc>
                        Your item must be unused and in the same condition that
                        you received it.The item must be in the original
                        packaging. To complete your return, we require a receipt
                        or proof of purchase. Only regular priced items may be
                        refunded, sale items cannot be refunded. If the item in
                        question was marked as a gift when purchased and shipped
                        directly to you, you will receive a gift credit for the
                        value of your return.
                    </Desc>
                    <Heading>Late or missing refunds</Heading>
                    <Desc>
                        If you have not received a refund yet, first check your
                        bank account again. Then contact your credit card
                        company, it may take some time before your refund is
                        officially posted. If you have done all of this and you
                        still have not received your refund yet, please contact
                        us.
                    </Desc>
                    <Heading>Shipping Policy</Heading>
                    <Desc>You
                        will be responsible for paying for your own shipping
                        costs for returning your item. Shipping costs are
                        non-refundable! If you receive a refund, the cost of
                        return shipping will be deducted from your refund.
                        Depending on where you live, the time it may take for
                        your exchanged product to reach you, may vary. Please
                        see, we cannot guarantee that we will receive your
                        returned item.
                    </Desc>
                </AboutContainer>
            </Container>
            <Newsletter />
            <Footer />
        </>
    );
};

export default Refund;
