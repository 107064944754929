import styled from "styled-components";
import Announcement from "../components/Announcement";
import Products from "../components/Products";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import { mobile, tablet } from "../responsive";
import { useLocation } from "react-router";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Container = styled.div``;

const Hero = styled.h1`
    padding: 20px;
    background-position: center;
    height: 35vh;
    display: flex;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    font-size: 3rem;
    background-size: cover;
    ${mobile({
        display: "none",
    })}
    ${tablet({
        display: "none",
    })}
`;

const MobileHero = styled.h1`
    padding: 20px;
    background-position: center;
    height: 35vh;
    display: none;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    font-size: 3rem;
    background-size: cover;
    ${mobile({
        display: "grid"
    })}
    ${tablet({
        display: "grid"
    })}
`;

const MobileTitleContainer = styled.div`
    display: none;
    grid-auto-flow: column;
    grid-template-column: auto auto auto;
    height: 10vh;
    background: #1f2f4d;
    ${mobile({
        display: "grid",
        width: "100vw"
    })}
    ${tablet({
        display: "grid",
        width: "100vw"
    })}
`;

const MobileTitle = styled.div`
    font-family: Jost-SemiBold;
    text-transform: uppercase;
    border: 1px solid white;
    color: white;
    text-align: center;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${mobile({
        width: "33.3vw"
    })}
    ${tablet({
        width: "33.3vw"
    })}
`;

const Title = styled.h1`
    font-family: Jost-Bold;
    text-transform: uppercase;
    background: #1f2f4d;
    color: white;
    font-size: 2.5rem;
    text-align: center;
    padding: 3rem 2rem;
    ${mobile({
        display: "none",
        textAlign: "center",
        fontSize: "1.5rem",
        padding: "3rem 2rem 3rem 2rem",
    })}
    ${tablet({
        display: "none",
        textAlign: "center",
        fontSize: "1.5rem",
        padding: "3rem 2rem 3rem 2rem",
    })}
`;

const ProductList = () => {
    const location = useLocation();
    const cat = location.pathname.split("/")[2];
    // eslint-disable-next-line
    const [filters, setFilters] = useState({});
    // eslint-disable-next-line
    const [sort, setSort] = useState("newest");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <Announcement />
            <Hero
                style={
                    cat === "self-winding"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/haofa/Haofa_SeriesWebBannerDesktop_New_kcSn0W-iD.jpg?updatedAt=1686531722999`}`,
                          }
                        : cat === "manual"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/haofa/Haofa_SeriesWebBannerDesktop_New2_ndJxhaRJK.jpg?updatedAt=1686531723091`}`,
                          }
                        : cat === "crystal"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/haofa/Haofa_SeriesWebBannerDesktop_New3_0XvH2DKdJ.jpg?updatedAt=1686531722933`}`,
                          }
                        : cat === "mechanical"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/haofa/Haofa_SeriesWebBannerDesktop_New5_bjHrdtPAJQ.jpg?updatedAt=1686531722988`}`,
                          }
                        : cat === "carousel"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/haofa/Haofa_SeriesWebBannerDesktop_New4_s9NNXQzDwp.jpg?updatedAt=1686462379002`}`,
                          }
                        : cat === "women"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/haofa/Haofa_SeriesWebBannerDesktop_New6_spqlHC62M.jpg?updatedAt=1686531722966`}`,
                          }
                        : {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/Haofa/Haofa_WebsiteBanner_Desktop11_3nTxcgvFA.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1669370066807`}`,
                          }
                }
            ></Hero>
                        <MobileHero
                style={
                    cat === "self-winding"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/haofa/Haofa_SeriesWebBannerMobile_New_yf9GJGsoB.jpg?updatedAt=1686462624428`}`,
                          }
                        : cat === "manual"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/haofa/Haofa_SeriesWebBannerMobile_New2_WYZDp1qTU.jpg?updatedAt=1686462624435`}`,
                          }
                        : cat === "crystal"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/haofa/Haofa_SeriesWebBannerMobile_New3_g8nXCi3mQc.jpg?updatedAt=1686462624402`}`,
                          }
                        : cat === "mechanical"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/haofa/Haofa_SeriesWebBannerMobile_New5_jgcZFjgtm.jpg?updatedAt=1686462624401`}`,
                          }
                        : cat === "carousel"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/haofa/Haofa_SeriesWebBannerMobile_New4_Bwyxu3qYY.jpg?updatedAt=1686462624409`}`,
                          }
                        : cat === "women"
                        ? {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/haofa/Haofa_SeriesWebBannerMobile_New6_9kvk245Y5.jpg?updatedAt=1686462624539`}`,
                          }
                        : {
                              backgroundImage: `url(${`https://ik.imagekit.io/dnddecpho/Haofa/Haofa_WebsiteBanner_Desktop11_3nTxcgvFA.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1669370066807`}`,
                          }
                }
            ></MobileHero>
            <Title>
                {cat === "self-winding"
                    ? "Self-Winding Tourbillon"
                    : cat === "manual"
                    ? "Manual Winding Tourbillon"
                    : cat === "crystal"
                    ? "Crystal Series"
                    : cat === "carousel"
                    ? "Carousel Series"
                    : cat === "mechanical"
                    ? "Automatic Mechanical Series"
                    : cat}
            </Title>
            <MobileTitleContainer>
                <MobileTitle
                    style={
                        location.pathname === "/products/self-winding"
                            ? {
                                  textDecoration: "none",
                                  color: "white",
                                  fontFamily: "Jost-SemiBold",
                                  background: "#ac997b",
                              }
                            : {}
                    }
                >
                    <Link
                        style={{ textDecoration: "none", color: "white" }}
                        to="/products/self-winding"
                    >
                        Self Winding Tourbillon{" "}
                    </Link>
                </MobileTitle>
                <MobileTitle
                    style={
                        location.pathname === "/products/manual"
                            ? {
                                  textDecoration: "none",
                                  color: "white",
                                  fontFamily: "Jost-SemiBold",
                                  background: "#ac997b",
                              }
                            : {}
                    }
                >
                    <Link
                        style={{ textDecoration: "none", color: "white" }}
                        to="/products/manual"
                    >
                        Manual Winding Tourbillon{" "}
                    </Link>
                </MobileTitle>
                <MobileTitle
                    style={
                        location.pathname === "/products/mechanical"
                            ? {
                                  textDecoration: "none",
                                  color: "white",
                                  fontFamily: "Jost-SemiBold",
                                  background: "#ac997b",
                              }
                            : {}
                    }
                >
                    <Link
                        style={{ textDecoration: "none", color: "white" }}
                        to="/products/mechanical"
                    >
                        Automatic Mechanical Series{" "}
                    </Link>
                </MobileTitle>
            </MobileTitleContainer>
            <MobileTitleContainer>
                <MobileTitle
                    style={
                        location.pathname === "/products/crystal"
                            ? {
                                  textDecoration: "none",
                                  color: "white",
                                  fontFamily: "Jost-SemiBold",
                                  background: "#ac997b",
                              }
                            : {}
                    }
                >
                    <Link
                        style={{ textDecoration: "none", color: "white" }}
                        to="/products/crystal"
                    >
                        Crystal Series{" "}
                    </Link>
                </MobileTitle>
                <MobileTitle
                    style={
                        location.pathname === "/products/carousel"
                            ? {
                                  textDecoration: "none",
                                  color: "white",
                                  fontFamily: "Jost-SemiBold",
                                  background: "#ac997b",
                              }
                            : {}
                    }
                >
                    <Link
                        style={{ textDecoration: "none", color: "white" }}
                        to="/products/carousel"
                    >
                        Carousel Series{" "}
                    </Link>
                </MobileTitle>
                <MobileTitle
                    style={
                        location.pathname === "/products/women"
                            ? {
                                  textDecoration: "none",
                                  color: "white",
                                  fontFamily: "Jost-SemiBold",
                                  background: "#ac997b",
                              }
                            : {}
                    }
                >
                    <Link
                        style={{ textDecoration: "none", color: "white" }}
                        to="/products/women"
                    >
                        Ladies Series{" "}
                    </Link>
                </MobileTitle>
            </MobileTitleContainer>
            <Products cat={cat} filters={filters} sort={sort} />
            <Newsletter />
            <Footer />
        </Container>
    );
};

export default ProductList;
